// source: references.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var annotations_pb = require('./annotations_pb.js');
goog.object.extend(proto, annotations_pb);
goog.exportSymbol('proto.org.couchers.api.references.AvailableWriteReferenceType', null, global);
goog.exportSymbol('proto.org.couchers.api.references.AvailableWriteReferencesReq', null, global);
goog.exportSymbol('proto.org.couchers.api.references.AvailableWriteReferencesRes', null, global);
goog.exportSymbol('proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq', null, global);
goog.exportSymbol('proto.org.couchers.api.references.ListPendingReferencesToWriteRes', null, global);
goog.exportSymbol('proto.org.couchers.api.references.ListReferencesReq', null, global);
goog.exportSymbol('proto.org.couchers.api.references.ListReferencesRes', null, global);
goog.exportSymbol('proto.org.couchers.api.references.Reference', null, global);
goog.exportSymbol('proto.org.couchers.api.references.ReferenceType', null, global);
goog.exportSymbol('proto.org.couchers.api.references.WriteFriendReferenceReq', null, global);
goog.exportSymbol('proto.org.couchers.api.references.WriteHostRequestReferenceReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.Reference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.references.Reference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.Reference.displayName = 'proto.org.couchers.api.references.Reference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.ListReferencesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.references.ListReferencesReq.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.references.ListReferencesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.ListReferencesReq.displayName = 'proto.org.couchers.api.references.ListReferencesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.ListReferencesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.references.ListReferencesRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.references.ListReferencesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.ListReferencesRes.displayName = 'proto.org.couchers.api.references.ListReferencesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.WriteFriendReferenceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.references.WriteFriendReferenceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.WriteFriendReferenceReq.displayName = 'proto.org.couchers.api.references.WriteFriendReferenceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.references.WriteHostRequestReferenceReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.WriteHostRequestReferenceReq.displayName = 'proto.org.couchers.api.references.WriteHostRequestReferenceReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.displayName = 'proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.AvailableWriteReferencesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.references.AvailableWriteReferencesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.AvailableWriteReferencesReq.displayName = 'proto.org.couchers.api.references.AvailableWriteReferencesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.AvailableWriteReferenceType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.references.AvailableWriteReferenceType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.AvailableWriteReferenceType.displayName = 'proto.org.couchers.api.references.AvailableWriteReferenceType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.references.AvailableWriteReferencesRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.references.AvailableWriteReferencesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.AvailableWriteReferencesRes.displayName = 'proto.org.couchers.api.references.AvailableWriteReferencesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.references.ListPendingReferencesToWriteRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.references.ListPendingReferencesToWriteRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.references.ListPendingReferencesToWriteRes.displayName = 'proto.org.couchers.api.references.ListPendingReferencesToWriteRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.Reference.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.Reference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.Reference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.Reference.toObject = function(includeInstance, msg) {
  var f, obj = {
    referenceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toUserId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    referenceType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    text: jspb.Message.getFieldWithDefault(msg, 5, ""),
    writtenTime: (f = msg.getWrittenTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.Reference}
 */
proto.org.couchers.api.references.Reference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.Reference;
  return proto.org.couchers.api.references.Reference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.Reference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.Reference}
 */
proto.org.couchers.api.references.Reference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReferenceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToUserId(value);
      break;
    case 4:
      var value = /** @type {!proto.org.couchers.api.references.ReferenceType} */ (reader.readEnum());
      msg.setReferenceType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setWrittenTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.Reference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.Reference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.Reference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.Reference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferenceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFromUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToUserId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getReferenceType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWrittenTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int64 reference_id = 1;
 * @return {number}
 */
proto.org.couchers.api.references.Reference.prototype.getReferenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.Reference} returns this
 */
proto.org.couchers.api.references.Reference.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 from_user_id = 2;
 * @return {number}
 */
proto.org.couchers.api.references.Reference.prototype.getFromUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.Reference} returns this
 */
proto.org.couchers.api.references.Reference.prototype.setFromUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 to_user_id = 3;
 * @return {number}
 */
proto.org.couchers.api.references.Reference.prototype.getToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.Reference} returns this
 */
proto.org.couchers.api.references.Reference.prototype.setToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ReferenceType reference_type = 4;
 * @return {!proto.org.couchers.api.references.ReferenceType}
 */
proto.org.couchers.api.references.Reference.prototype.getReferenceType = function() {
  return /** @type {!proto.org.couchers.api.references.ReferenceType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.org.couchers.api.references.ReferenceType} value
 * @return {!proto.org.couchers.api.references.Reference} returns this
 */
proto.org.couchers.api.references.Reference.prototype.setReferenceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.org.couchers.api.references.Reference.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.references.Reference} returns this
 */
proto.org.couchers.api.references.Reference.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp written_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.references.Reference.prototype.getWrittenTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.references.Reference} returns this
*/
proto.org.couchers.api.references.Reference.prototype.setWrittenTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.references.Reference} returns this
 */
proto.org.couchers.api.references.Reference.prototype.clearWrittenTime = function() {
  return this.setWrittenTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.references.Reference.prototype.hasWrittenTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 host_request_id = 7;
 * @return {number}
 */
proto.org.couchers.api.references.Reference.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.Reference} returns this
 */
proto.org.couchers.api.references.Reference.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.references.ListReferencesReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.ListReferencesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.ListReferencesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.ListReferencesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromUserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    toUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    referenceTypeFilterList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.ListReferencesReq}
 */
proto.org.couchers.api.references.ListReferencesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.ListReferencesReq;
  return proto.org.couchers.api.references.ListReferencesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.ListReferencesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.ListReferencesReq}
 */
proto.org.couchers.api.references.ListReferencesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToUserId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.org.couchers.api.references.ReferenceType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReferenceTypeFilter(values[i]);
      }
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.ListReferencesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.ListReferencesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.ListReferencesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromUserId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getToUserId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getReferenceTypeFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint64 from_user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.getFromUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.ListReferencesReq} returns this
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.setFromUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to_user_id = 2;
 * @return {number}
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.getToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.ListReferencesReq} returns this
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.setToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ReferenceType reference_type_filter = 3;
 * @return {!Array<!proto.org.couchers.api.references.ReferenceType>}
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.getReferenceTypeFilterList = function() {
  return /** @type {!Array<!proto.org.couchers.api.references.ReferenceType>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.org.couchers.api.references.ReferenceType>} value
 * @return {!proto.org.couchers.api.references.ListReferencesReq} returns this
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.setReferenceTypeFilterList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.org.couchers.api.references.ReferenceType} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.references.ListReferencesReq} returns this
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.addReferenceTypeFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.references.ListReferencesReq} returns this
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.clearReferenceTypeFilterList = function() {
  return this.setReferenceTypeFilterList([]);
};


/**
 * optional uint32 page_size = 4;
 * @return {number}
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.ListReferencesReq} returns this
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string page_token = 5;
 * @return {string}
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.references.ListReferencesReq} returns this
 */
proto.org.couchers.api.references.ListReferencesReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.references.ListReferencesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.ListReferencesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.ListReferencesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.ListReferencesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.ListReferencesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    referencesList: jspb.Message.toObjectList(msg.getReferencesList(),
    proto.org.couchers.api.references.Reference.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.ListReferencesRes}
 */
proto.org.couchers.api.references.ListReferencesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.ListReferencesRes;
  return proto.org.couchers.api.references.ListReferencesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.ListReferencesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.ListReferencesRes}
 */
proto.org.couchers.api.references.ListReferencesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.references.Reference;
      reader.readMessage(value,proto.org.couchers.api.references.Reference.deserializeBinaryFromReader);
      msg.addReferences(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.ListReferencesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.ListReferencesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.ListReferencesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.ListReferencesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.references.Reference.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Reference references = 1;
 * @return {!Array<!proto.org.couchers.api.references.Reference>}
 */
proto.org.couchers.api.references.ListReferencesRes.prototype.getReferencesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.references.Reference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.references.Reference, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.references.Reference>} value
 * @return {!proto.org.couchers.api.references.ListReferencesRes} returns this
*/
proto.org.couchers.api.references.ListReferencesRes.prototype.setReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.references.Reference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.references.Reference}
 */
proto.org.couchers.api.references.ListReferencesRes.prototype.addReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.references.Reference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.references.ListReferencesRes} returns this
 */
proto.org.couchers.api.references.ListReferencesRes.prototype.clearReferencesList = function() {
  return this.setReferencesList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.references.ListReferencesRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.references.ListReferencesRes} returns this
 */
proto.org.couchers.api.references.ListReferencesRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.WriteFriendReferenceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.WriteFriendReferenceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    toUserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    privateText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    wasAppropriate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.WriteFriendReferenceReq}
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.WriteFriendReferenceReq;
  return proto.org.couchers.api.references.WriteFriendReferenceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.WriteFriendReferenceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.WriteFriendReferenceReq}
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateText(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWasAppropriate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.WriteFriendReferenceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.WriteFriendReferenceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrivateText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWasAppropriate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int64 to_user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.getToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.WriteFriendReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.setToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.references.WriteFriendReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string private_text = 5;
 * @return {string}
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.getPrivateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.references.WriteFriendReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.setPrivateText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool was_appropriate = 3;
 * @return {boolean}
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.getWasAppropriate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.references.WriteFriendReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.setWasAppropriate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional double rating = 4;
 * @return {number}
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.WriteFriendReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteFriendReferenceReq.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.WriteHostRequestReferenceReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.WriteHostRequestReferenceReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    privateText: jspb.Message.getFieldWithDefault(msg, 5, ""),
    wasAppropriate: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.WriteHostRequestReferenceReq}
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.WriteHostRequestReferenceReq;
  return proto.org.couchers.api.references.WriteHostRequestReferenceReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.WriteHostRequestReferenceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.WriteHostRequestReferenceReq}
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateText(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWasAppropriate(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRating(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.WriteHostRequestReferenceReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.WriteHostRequestReferenceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrivateText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWasAppropriate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.WriteHostRequestReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.references.WriteHostRequestReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string private_text = 5;
 * @return {string}
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.getPrivateText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.references.WriteHostRequestReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.setPrivateText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool was_appropriate = 3;
 * @return {boolean}
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.getWasAppropriate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.references.WriteHostRequestReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.setWasAppropriate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional double rating = 4;
 * @return {number}
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.WriteHostRequestReferenceReq} returns this
 */
proto.org.couchers.api.references.WriteHostRequestReferenceReq.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reasonDidntMeetup: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq}
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq;
  return proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq}
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonDidntMeetup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReasonDidntMeetup();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq} returns this
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string reason_didnt_meetup = 6;
 * @return {string}
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.prototype.getReasonDidntMeetup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq} returns this
 */
proto.org.couchers.api.references.HostRequestIndicateDidntMeetupReq.prototype.setReasonDidntMeetup = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.AvailableWriteReferencesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.AvailableWriteReferencesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.AvailableWriteReferencesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.AvailableWriteReferencesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    toUserId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.AvailableWriteReferencesReq}
 */
proto.org.couchers.api.references.AvailableWriteReferencesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.AvailableWriteReferencesReq;
  return proto.org.couchers.api.references.AvailableWriteReferencesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.AvailableWriteReferencesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.AvailableWriteReferencesReq}
 */
proto.org.couchers.api.references.AvailableWriteReferencesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.AvailableWriteReferencesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.AvailableWriteReferencesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.AvailableWriteReferencesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.AvailableWriteReferencesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 to_user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.references.AvailableWriteReferencesReq.prototype.getToUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.AvailableWriteReferencesReq} returns this
 */
proto.org.couchers.api.references.AvailableWriteReferencesReq.prototype.setToUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.AvailableWriteReferenceType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.AvailableWriteReferenceType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    referenceType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timeExpires: (f = msg.getTimeExpires()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.AvailableWriteReferenceType}
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.AvailableWriteReferenceType;
  return proto.org.couchers.api.references.AvailableWriteReferenceType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.AvailableWriteReferenceType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.AvailableWriteReferenceType}
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHostRequestId(value);
      break;
    case 2:
      var value = /** @type {!proto.org.couchers.api.references.ReferenceType} */ (reader.readEnum());
      msg.setReferenceType(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimeExpires(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.AvailableWriteReferenceType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.AvailableWriteReferenceType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReferenceType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTimeExpires();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 host_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.getHostRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.references.AvailableWriteReferenceType} returns this
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.setHostRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ReferenceType reference_type = 2;
 * @return {!proto.org.couchers.api.references.ReferenceType}
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.getReferenceType = function() {
  return /** @type {!proto.org.couchers.api.references.ReferenceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.org.couchers.api.references.ReferenceType} value
 * @return {!proto.org.couchers.api.references.AvailableWriteReferenceType} returns this
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.setReferenceType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp time_expires = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.getTimeExpires = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.references.AvailableWriteReferenceType} returns this
*/
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.setTimeExpires = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.references.AvailableWriteReferenceType} returns this
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.clearTimeExpires = function() {
  return this.setTimeExpires(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.references.AvailableWriteReferenceType.prototype.hasTimeExpires = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.AvailableWriteReferencesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.AvailableWriteReferencesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    canWriteFriendReference: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    availableWriteReferencesList: jspb.Message.toObjectList(msg.getAvailableWriteReferencesList(),
    proto.org.couchers.api.references.AvailableWriteReferenceType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.AvailableWriteReferencesRes}
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.AvailableWriteReferencesRes;
  return proto.org.couchers.api.references.AvailableWriteReferencesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.AvailableWriteReferencesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.AvailableWriteReferencesRes}
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanWriteFriendReference(value);
      break;
    case 2:
      var value = new proto.org.couchers.api.references.AvailableWriteReferenceType;
      reader.readMessage(value,proto.org.couchers.api.references.AvailableWriteReferenceType.deserializeBinaryFromReader);
      msg.addAvailableWriteReferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.AvailableWriteReferencesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.AvailableWriteReferencesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCanWriteFriendReference();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAvailableWriteReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.org.couchers.api.references.AvailableWriteReferenceType.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool can_write_friend_reference = 1;
 * @return {boolean}
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.prototype.getCanWriteFriendReference = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.references.AvailableWriteReferencesRes} returns this
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.prototype.setCanWriteFriendReference = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated AvailableWriteReferenceType available_write_references = 2;
 * @return {!Array<!proto.org.couchers.api.references.AvailableWriteReferenceType>}
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.prototype.getAvailableWriteReferencesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.references.AvailableWriteReferenceType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.references.AvailableWriteReferenceType, 2));
};


/**
 * @param {!Array<!proto.org.couchers.api.references.AvailableWriteReferenceType>} value
 * @return {!proto.org.couchers.api.references.AvailableWriteReferencesRes} returns this
*/
proto.org.couchers.api.references.AvailableWriteReferencesRes.prototype.setAvailableWriteReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.org.couchers.api.references.AvailableWriteReferenceType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.references.AvailableWriteReferenceType}
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.prototype.addAvailableWriteReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.org.couchers.api.references.AvailableWriteReferenceType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.references.AvailableWriteReferencesRes} returns this
 */
proto.org.couchers.api.references.AvailableWriteReferencesRes.prototype.clearAvailableWriteReferencesList = function() {
  return this.setAvailableWriteReferencesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.references.ListPendingReferencesToWriteRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.references.ListPendingReferencesToWriteRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingReferencesList: jspb.Message.toObjectList(msg.getPendingReferencesList(),
    proto.org.couchers.api.references.AvailableWriteReferenceType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.references.ListPendingReferencesToWriteRes}
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.references.ListPendingReferencesToWriteRes;
  return proto.org.couchers.api.references.ListPendingReferencesToWriteRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.references.ListPendingReferencesToWriteRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.references.ListPendingReferencesToWriteRes}
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.references.AvailableWriteReferenceType;
      reader.readMessage(value,proto.org.couchers.api.references.AvailableWriteReferenceType.deserializeBinaryFromReader);
      msg.addPendingReferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.references.ListPendingReferencesToWriteRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.references.ListPendingReferencesToWriteRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.references.AvailableWriteReferenceType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AvailableWriteReferenceType pending_references = 1;
 * @return {!Array<!proto.org.couchers.api.references.AvailableWriteReferenceType>}
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.prototype.getPendingReferencesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.references.AvailableWriteReferenceType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.references.AvailableWriteReferenceType, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.references.AvailableWriteReferenceType>} value
 * @return {!proto.org.couchers.api.references.ListPendingReferencesToWriteRes} returns this
*/
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.prototype.setPendingReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.references.AvailableWriteReferenceType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.references.AvailableWriteReferenceType}
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.prototype.addPendingReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.references.AvailableWriteReferenceType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.references.ListPendingReferencesToWriteRes} returns this
 */
proto.org.couchers.api.references.ListPendingReferencesToWriteRes.prototype.clearPendingReferencesList = function() {
  return this.setPendingReferencesList([]);
};


/**
 * @enum {number}
 */
proto.org.couchers.api.references.ReferenceType = {
  REFERENCE_TYPE_FRIEND: 0,
  REFERENCE_TYPE_SURFED: 1,
  REFERENCE_TYPE_HOSTED: 2
};

goog.object.extend(exports, proto.org.couchers.api.references);
